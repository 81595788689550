import { initializeApp } from "firebase/app";
import data from "../tenant-config.json";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const apiKey = data.firebase ?? config.FIREBASE_API_KEY;
  const firebaseConfig = {
    apiKey,
  };

  // Initialize Firebase
  initializeApp(firebaseConfig);
});
